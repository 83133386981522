import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Home from './pages/index.js';
import Download from './pages/download.js';
import Api from './pages/api.js';
import Contact from './pages/contact.js';
import Dashboard from './pages/dashboard.js';
import Nav from './component/navbar/navbar.js';
// import Map_page from './pages/map.js';
import { createBrowserHistory } from "history";

const hist = createBrowserHistory();

function App() {
	return (
		<Router>
			<div>
				<Nav />
				<div>
					<Routes history={hist}>
						<Route exact path='/' element={<Home />} />
						<Route exact path='/download' element={<Download />} />
						<Route exact path='/api' element={<Api />} />
						<Route exact path='/contact' element={<Contact />} />
						<Route exact path='/dashboard' element={<Dashboard />} />
						{/* <Route path='/map' element={<Map_page />} /> */}
						{/* <Route path='/download' element={<Download />} /> */}
						<Route path="*" element={<Navigate replace to="/" />} />
					</Routes>
				</div>
			</div>

		</Router>
	);
}

export default App;
