import * as React from "react";
import Iframe from "react-iframe";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import Lineicon from '../img/line-logo-svgrepo-com.svg'

import "./Contact.css"; // Import CSS
import { Box } from "@mui/material";

import mhesi from '../img/MHESI_LOGO.png';
import gistda from '../img/GISTDA_LOGO.png';
import escap from '../img/ESCAP_LOGO.png';
import nier from '../img/NIER_LOGO.jpg'

let sphere = null;
let map = null;


function Contact() {

  return (
    <div className="Contact-page">
      <div className="contact-container">
        <Box height={64}></Box>

        <div className="Contact-map">
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.2854941274345!2d100.56183007485843!3d13.881869986523668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2832ff601e93b%3A0x4adcb46a8124f677!2zR0lTVERBICjguKrguLPguJnguLHguIHguIfguLLguJnguYPguKvguI3guYgpIOC4quC4s-C4meC4seC4geC4h-C4suC4meC4nuC4seC4kuC4meC4suC5gOC4l-C4hOC5guC4meC5guC4peC4ouC4teC4reC4p-C4geC4suC4qOC5geC4peC4sOC4oOC4ueC4oeC4tOC4quC4suC4o-C4quC4meC5gOC4l-C4qCAo4Lit4LiH4LiE4LmM4LiB4Liy4Lij4Lih4Lir4Liy4LiK4LiZKQ!5e0!3m2!1sth!2sth!4v1725434829392!5m2!1sth!2sth"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></Iframe>
          {/* <Box id="map-sphere" ></Box> */}
        </div>
        <div className="contact-content">
          <div className="contact-content-wrap">
            <div className="contact-start">
              <div className="contact-head">
                <h1>ช่องทางการติดต่อเรา</h1>
              </div>
              <div className="contact-box">
                <div className="contact-icon">
                  <PlaceOutlinedIcon sx={{ color: "white" }} />
                </div>
                <div className="contact-text">
                  <p>
                    สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน)
                    ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษา 5 ธันวาคม 2550 เลขที่ 120
                    อาคารรวมหน่วยราชการ (อาคารรัฐประศาสนภักดี) ชั้น 6 และชั้น 7
                    ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210
                  </p>
                </div>
              </div>

              <div className="contact-box">
                <div className="contact-icon">
                  <PhoneOutlinedIcon sx={{ color: "white" }} />
                </div>
                <div className="contact-text">
                  <p>
                    0 2141 4574
                  </p>
                </div>
              </div>

              <div className="contact-box">
                <div className="contact-icon">
                  <EmailOutlinedIcon sx={{ color: "white" }} />
                </div>
                <div className="contact-text">
                  <p>
                    env.disaster@gmail.com
                  </p>
                </div>
              </div>

              <div className="contact-box">
                <div className="contact-icon">
                  <img src={Lineicon} alt="Lineicon" style={{ height: '20px' }} />
                </div>
                <div className="contact-text">
                  <p>
                    Line:
                  </p>
                </div>
              </div>

              <div className="contact-box">
                <div className="contact-icon">
                  <FeedOutlinedIcon sx={{ color: "white" }} />
                </div>
                <div className="contact-text">
                  <p>
                    แบบฟอร์มขออนุเคราะห์ข้อมูลทรัพยากรธรรมชาติ สิ่งแวดล้อม และภัยพิบัติจากดาวเทียม (ข้อมูลย้อนหลัง)
                  </p>
                </div>
              </div>

              <div className="contact-box-logo">
                <img src={mhesi} alt="MHESI" />
                <img src={gistda} alt="GISTDA" />
                <img src={escap} alt="ESCAP" />
                <img src={nier} alt="NIER" />
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;