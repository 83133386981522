import React, { useRef, useState, useEffect } from 'react';
// import LineChart from '../component/component/barChart';

// import Highcharts from 'highcharts'
import Highcharts, { color, roundedRect } from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Skeleton,
    Grid,
    Button,
    Fab,
    FormHelperText,
    Typography,
    colors
} from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableFooter from '@mui/material/TableFooter';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { Switch, Autocomplete, TextField } from '@mui/material/';
import CircularProgress from '@mui/material/CircularProgress';
import { Icon } from "@mui/material/";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { makeStyles } from '@mui/styles';


import picAOD from '../img/AOD.svg';
import picNO2 from '../img/NO2.svg';
import picO3 from '../img/O3.svg';
import picSO2 from '../img/SO2.svg';
import picSSA from '../img/SSA.svg';
import picPM25 from '../img/PM25.svg';
import picUVAI from '../img/UVAI.svg';
import picVIAI from '../img/VIAI.svg';
import search from '../img/search1.svg';
import increase from '../img/increase.svg';
import decrease from '../img/decrease.svg';
import stable from '../img/stable.svg'

import {
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from "@mui/material";


const Dashboard = () => {
    const [fetchedData, setFetchedData] = React.useState([]);
    // const urlPath = 'http://127.0.0.1:5000';
    const urlPath = 'https://air.gistda.or.th/';
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [isLoading1, setisLoading1] = useState(true);
    const [isLoading2, setisLoading2] = useState(true);
    const [isLoading3, setisLoading3] = useState(true);

    const [allData, setAllData] = useState([]);
    const [allTime, setAllTime] = useState([]);
    const [allData2, setAllData2] = useState([]);
    const [allTime2, setAllTime2] = useState([]);
    const [allData3, setAllData3] = useState([]);
    const [allTime3, setAllTime3] = useState([]);

    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);

    const [isLoadingPv, setIsLoadingPv] = useState(true);
    const [isLoadingAp, setIsLoadingAp] = useState(true);
    const [stl, setStl] = useState([
        { 'id': 'THA', 'th': 'ราชอาณาจักรไทย' },
        { 'id': 'KHM', 'th': 'ราชอาณาจักรกัมพูชา' },
        { 'id': 'LAO', 'th': 'สาธารณรัฐประชาธิปไตยประชาชนลาว' },
        { 'id': 'MMR', 'th': 'สาธารณรัฐแห่งสหภาพเมียนมาร์' },
        { 'id': 'MYS', 'th': 'มาเลเซีย (ตะวันตก)' },
        { 'id': 'VNM', 'th': 'สาธารณรัฐสังคมนิยมเวียดนาม' }
    ]);
    const [pvl, setPvl] = useState([]);
    const [apl, setApl] = useState([]);

    const [sid, setSid] = useState(null);
    const [pid, setPid] = useState(null);
    const [aid, setAid] = useState(null);
    const [wid, setWid] = useState(null);

    const [open0, setOpen0] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    // const [options, setOptions] = React.useState([]);
    const loading0 = stl.length === 0;
    const loadingPv = isLoadingPv && open1 && pvl.length === 0;
    const loadingAp = isLoadingAp && open2 && apl.length === 0;
    const [value0, setValue0] = React.useState([]);
    const [value1, setValue1] = React.useState([]);
    const [value2, setValue2] = React.useState([]);

    const [var0, setVar0] = React.useState(null);
    const [var1, setVar1] = React.useState(null);
    const [value, setValue] = React.useState(new dayjs());

    const [value45, setValue45] = React.useState([]);
    const [isLoadingHr, setIsLoadingHr] = useState(true);
    const [hours, setHours] = useState([]);
    const [avgData, setAvgData] = useState([]);

    const [minMax, setMinMax] = useState([]);


    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    const getTime = async (dt, type) => {
        setIsLoadingHr(true);

        const response = await fetch(
            urlPath + "/rest/getHours?dt=" + dt + "&type=" + type
        );
        const result = await response.json();

        let r = result.data.map((data) => ({
            product: data.product,
            dt: dayjs(data.dt).toISOString().replace("T", " ").substring(0, 16),
        }));

        const labels = [...new Set(r.map((item) => item.dt.split(' ')[1]))];

        setHours(labels);
        setValue45(labels[0])
        setIsLoadingHr(false);
    };

    const getMinMax = async () => {

        const response = await fetch(
            urlPath + "/rest/getMinMaxDate"
        );
        const result = await response.json();

        setValue(dayjs(result.data.max))
        setMinMax(result.data);

        return dayjs(result.data.max);
    };

    const getPvl = async (id) => {
        // console.log(urlPath + "/rest/getProvName?id=" + sid);
        const response = await fetch(
            urlPath + "/rest/getProvName?id=" + id
        );
        const result = await response.json();

        let d = result.data
        console.log(d);


        setPvl(d);
        setIsLoadingPv(false);

        console.log(pvl);
    };

    const getApl = async (id) => {
        const response = await fetch(
            urlPath + "/rest/getAmpName?id=" + id
        );
        const result = await response.json();

        let d = result.data;

        setApl(d);
        // setIsLoadingAp(false);
    };

    ///////////////////////////////////////

    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    // The function takes in a dataset and calculates how many empty tiles needed
    // before and after the dataset is plotted.
    function generateChartData(data) {

        // Calculate the starting weekday index (0-6 of the first date in the given
        // array)
        const firstWeekday = new Date(data[0].date).getDay(),
            // cMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
            monthLength = new Date(new Date(data[0].date).getFullYear(), new Date(data[0].date).getMonth() + 1, 0).getDate(),
            lastElement = data.slice(-1).date,
            lastWeekday = new Date(lastElement).getDay(),
            lengthOfWeek = 6,
            emptyTilesFirst = firstWeekday,
            chartData = [];

        // Add the empty tiles before the first day of the month with null values to
        // take up space in the chart
        for (let emptyDay = 0; emptyDay < emptyTilesFirst; emptyDay++) {
            chartData.push({
                x: emptyDay,
                y: 5,
                value: null,
                date: null,
                custom: {
                    empty: true
                }
            });
        }

        // Loop through and populate with temperature and dates from the dataset
        for (let day = monthLength; day >= 1; day--) {
            // Get date from the given data array
            let date = null; try { date = data[day - 1].date; } catch { }
            // Offset by thenumber of empty tiles
            const xCoordinate = (emptyTilesFirst + day - 1) % 7;
            const yCoordinate = Math.floor((firstWeekday + day - 1) / 7);
            const id = day;

            // Get the corresponding temperature for the current day from the given
            // array
            let value = null; try { value = data[day - 1].value; } catch { }

            chartData.push({
                x: xCoordinate,
                y: 5 - yCoordinate,
                value: value,
                date: new Date(date).getTime(),
                custom: {
                    monthDay: new dayjs(date).format('YYYY/MM/DD'),
                    day: date ? date.split('-')[2] : date,
                }
            });
        }

        // Fill in the missing values when dataset is looped through.
        const emptyTilesLast = lengthOfWeek - lastWeekday;
        for (let emptyDay = 1; emptyDay <= emptyTilesLast; emptyDay++) {
            chartData.push({
                x: (lastWeekday + emptyDay) % 7,
                y: 0,
                value: null,
                date: null,
                custom: {
                    empty: true
                }
            });
        }
        // console.log(chartData);
        return chartData;
    }

    let options4 = {
        credits: {
            enabled: false
        },

        chart: {
            type: 'heatmap'
        },

        title: {
            text: `ปฏิทิน ${var1}`,
            align: 'left'
        },


        accessibility: {
            landmarkVerbosity: 'one'
        },

        tooltip: {
            enabled: true,
            outside: true,
            zIndex: 20,
            headerFormat: 'วันที่:',
            pointFormat: '{#unless ' +
                'point.custom.empty}{point.custom.monthDay}{/unless}' + '\n' +
                '= {#unless point.custom.empty}{point.value:.2f}{/unless}',
            nullFormat: 'No data'
        },

        xAxis: {
            categories: weekdays,
            opposite: true,
            lineWidth: 26,
            offset: 13,
            lineColor: 'rgba(27, 26, 37, 0.2)',
            labels: {
                rotation: 0,
                y: 20,
                style: {
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                }
            },
            accessibility: {
                description: 'weekdays',
                rangeDescription: 'X Axis is showing all 7 days of the week, ' +
                    'starting with Sunday.'
            }
        },

        yAxis: {
            min: 0,
            max: 5,
            accessibility: {
                description: 'weeks'
            },
            visible: false
        },

        legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'middle'
        },

        colorAxis: {
            // reversed: (['SSA354', 'SSA443', 'SSA500'].includes(var1)),
            min: (['AOD354', 'AOD443', 'AOD500'].includes(var1)) ? 0
                : (['UVAI'].includes(var1)) ? -1
                    : (['VIAI'].includes(var1)) ? -1.5
                        : (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) ? 0
                            : (['O3Total'].includes(var1)) ? 200
                                : (['SO2'].includes(var1)) ? 0
                                    : (['SSA354', 'SSA443', 'SSA500'].includes(var1)) ? 0.8
                                        : (['PM25'].includes(var1)) ? 0 : 0,
            max: (['AOD354', 'AOD443', 'AOD500'].includes(var1)) ? 1
                : (['UVAI'].includes(var1)) ? 4
                    : (['VIAI'].includes(var1)) ? 1.5
                        : (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) ? 15000000000000000
                            : (['O3Total'].includes(var1)) ? 320
                                : (['SO2'].includes(var1)) ? 15000000000000000
                                    : (['SSA354', 'SSA443', 'SSA500'].includes(var1)) ? 1
                                        : (['PM25'].includes(var1)) ? 75 : 1,
            minColor: (['SSA354', 'SSA443', 'SSA500'].includes(var1)) ? '#723288' : '#FFCBFF',
            maxColor: (['SSA354', 'SSA443', 'SSA500'].includes(var1)) ? '#FFCBFF' : '#723288',
            // stops:
            //     ['AOD354', 'AOD443', 'AOD500'].includes(var1) ? ([
            //         [0.000, '#FFCBFF'],
            //         [0.167, '#EDB7FF'],
            //         [0.333, '#DA89F5'],
            //         [0.500, '#B95DD8'],
            //         [0.667, '#9B4FB5'],
            //         [0.833, '#86469C'],
            //         [1.000, '#723288'],
            //     ]) : ['UVAI'].includes(var1) ? ([
            //         [-1.000, '#FFCBFF'],
            //         [-0.167, '#EDB7FF'],
            //         [0.667, '#DA89F5'],
            //         [1.500, '#B95DD8'],
            //         [2.333, '#9B4FB5'],
            //         [3.167, '#86469C'],
            //         [4.000, '#723288']
            //     ]) : ['VIAI'].includes(var1) ? ([
            //         [-1.5, '#FFCBFF'],
            //         [-1.0, '#EDB7FF'],
            //         [-0.5, '#DA89F5'],
            //         [0.0, '#B95DD8'],
            //         [0.5, '#9B4FB5'],
            //         [1.0, '#86469C'],
            //         [1.5, '#723288']
            //     ]) : ['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1) ? ([
            //         [0, '#FFCBFF'],
            //         [2500000000000000, '#EDB7FF'],
            //         [5000000000000000, '#DA89F5'],
            //         [7500000000000000, '#B95DD8'],
            //         [10000000000000000, '#9B4FB5'],
            //         [12500000000000000, '#86469C'],
            //         [15000000000000000, '#723288']
            //     ]) : ['O3Total'].includes(var1) ? ([
            //         [200, '#FFCBFF'],
            //         [220, '#EDB7FF'],
            //         [240, '#DA89F5'],
            //         [260, '#B95DD8'],
            //         [280, '#9B4FB5'],
            //         [300, '#86469C'],
            //         [320, '#723288']
            //     ]) : ['SO2'].includes(var1) ? ([
            //         [0, '#FFCBFF'],
            //         [2500000000000000, '#EDB7FF'],
            //         [5000000000000000, '#DA89F5'],
            //         [7500000000000000, '#B95DD8'],
            //         [10000000000000000, '#9B4FB5'],
            //         [12500000000000000, '#86469C'],
            //         [15000000000000000, '#723288']
            //     ]) : ['SSA354', 'SSA443', 'SSA500'].includes(var1) ? ([
            //         [1.000, '#FFCBFF'],
            //         [0.967, '#EDB7FF'],
            //         [0.933, '#DA89F5'],
            //         [0.900, '#B95DD8'],
            //         [0.867, '#9B4FB5'],
            //         [0.833, '#86469C'],
            //         [0.800, '#723288']
            //     ]) : ['PM25'].includes(var1) ? ([
            //         [0.0, '#FFCBFF'],
            //         [15.0, '#EDB7FF'],
            //         [25.0, '#DA89F5'],
            //         [37.5, '#B95DD8'],
            //         [75.0, '#723288']
            //     ]) : ([
            //     ]),
            labels: {
                format: '{value}'
            }
        },

        series: [{
            pointPadding: 3,
            roundedRect: 3,
            keys: ['x', 'y', 'value', 'date', 'id'],
            data: allData3,
            nullColor: 'rgba(196, 196, 196, 0.2)',
            borderWidth: 2,
            borderColor: 'rgba(196, 196, 196, 0.2)',
            dataLabels: [{
                enabled: true,
                format: '',
                style: {
                    textOutline: 'none',
                    fontWeight: 'normal',
                    fontSize: '1rem'
                },
                y: 4
            }, {
                enabled: true,
                align: 'left',
                verticalAlign: 'top',
                format: '{#unless ' +
                    'point.custom.empty}{point.custom.day}{/unless}',
                backgroundColor: 'whitesmoke',
                padding: 2,
                style: {
                    textOutline: 'none',
                    color: 'rgba(70, 70, 92, 1)',
                    fontSize: '0.5rem',
                    fontWeight: 'bold',
                    opacity: 0.5
                },
                x: 1,
                y: 1
            }]
        }]
    };



    ///////////////////////////////////////

    const options1 = {
        credits: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left'
        },
        yAxis: {
            title: {
                text: var1
            },
            min: (['AOD354', 'AOD443', 'AOD500'].includes(var1)) ? 0
                : (['UVAI'].includes(var1)) ? -1
                    : (['VIAI'].includes(var1)) ? -1.5
                        : (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) ? 0
                            : (['O3Total'].includes(var1)) ? 200
                                : (['SO2'].includes(var1)) ? 0
                                    : (['SSA354', 'SSA443', 'SSA500'].includes(var1)) ? 0.8
                                        : (['PM25'].includes(var1)) ? 0 : 0,
            max: (['AOD354', 'AOD443', 'AOD500'].includes(var1)) ? 1
                : (['UVAI'].includes(var1)) ? 4
                    : (['VIAI'].includes(var1)) ? 1.5
                        : (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) ? 1.5
                            : (['O3Total'].includes(var1)) ? 320
                                : (['SO2'].includes(var1)) ? 1.5
                                    : (['SSA354', 'SSA443', 'SSA500'].includes(var1)) ? 1
                                        : (['PM25'].includes(var1)) ? 75 : 1,
        },
        xAxis: {
            categories: allTime,
            // plotBands: [
            //     { from: allTime.length - cData[1] - 0.5, to: allTime.length - 0.5, color: '#EEE' },
            // ],
        },

        // legend: {
        //   layout: 'vertical',
        //   align: 'center',
        //   verticalAlign: 'top'
        // },
        chart: {
            type: 'column',
            // zoomType: 'x',
            // panning: true,
            // panKey: 'shift'
        },
        plotOptions: {
            // spline: {
            //     marker: {
            //         radius: 4,
            //         lineColor: '#666666',
            //         lineWidth: 1
            //     }
            // },
            series: {
                borderRadius: {
                    radius: 3
                },
                borderWidth: 0.25,
                // pointWidth: 15
            },
        },
        series: [{
            name: var1,
            data: allData.slice(0, 10),
            borderColor: '#000',
            // color: '#ffa500'
        }],

        responsive: {
            rules: [{
                condition: {
                    // maxWidth: 350
                    // width: '600px'
                },
                chartOptions: {
                    legend: { enabled: false },
                }
            }]
        },

        tooltip: {
            enabled: true,
            outside: true,
            zIndex: 20,
            valueDecimals: 2,
            nullFormat: 'No data'
        },

    }

    const options2 = {
        credits: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left'
        },
        yAxis: {
            title: {
                text: var1
            },
            min: (['AOD354', 'AOD443', 'AOD500'].includes(var1)) ? 0
                : (['UVAI'].includes(var1)) ? -1
                    : (['VIAI'].includes(var1)) ? -1.5
                        : (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) ? 0
                            : (['O3Total'].includes(var1)) ? 200
                                : (['SO2'].includes(var1)) ? 0
                                    : (['SSA354', 'SSA443', 'SSA500'].includes(var1)) ? 0.8
                                        : (['PM25'].includes(var1)) ? 0 : 0,
            max: (['AOD354', 'AOD443', 'AOD500'].includes(var1)) ? 1
                : (['UVAI'].includes(var1)) ? 4
                    : (['VIAI'].includes(var1)) ? 1.5
                        : (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) ? 1.5
                            : (['O3Total'].includes(var1)) ? 320
                                : (['SO2'].includes(var1)) ? 1.5
                                    : (['SSA354', 'SSA443', 'SSA500'].includes(var1)) ? 1
                                        : (['PM25'].includes(var1)) ? 75 : 1,
        },
        xAxis: {
            categories: allTime2,
            // plotBands: [
            //     { from: allTime.length - cData[1] - 0.5, to: allTime.length - 0.5, color: '#EEE' },
            // ],
        },

        // legend: {
        //   layout: 'vertical',
        //   align: 'center',
        //   verticalAlign: 'top'
        // },
        chart: {
            type: 'line',
            // zoomType: 'x',
            // panning: true,
            // panKey: 'shift'
        },
        plotOptions: {
            // spline: {
            //     marker: {
            //         radius: 4,
            //         lineColor: '#666666',
            //         lineWidth: 1
            //     }
            // },
            series: {
                borderRadius: {
                    radius: 3
                },
                borderWidth: 0.25,
                color: '#FFCBFF',
                // pointWidth: 15
            },
        },
        series: [{
            name: var1,
            data: allData2,

            marker: {
                radius: 5
            },
            // dataSorting: {
            //     enabled: true,
            // },
            borderColor: '#000',
            // color: '#ffa500'
        }],

        tooltip: {
            enabled: true,
            outside: true,
            zIndex: 20,
            valueDecimals: 2,
            nullFormat: 'No data'
        },

        responsive: {
            rules: [{
                condition: {
                    // maxWidth: 350
                    // width: '600px'
                },
                chartOptions: {
                    legend: { enabled: false },
                }
            }]
        }

    }

    const options3 = {
        credits: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left'
        },
        yAxis: {
            title: {
                text: var1
            },
            // min: 0
        },
        xAxis: {
            categories: allTime3,
            // plotBands: [
            //     { from: allTime.length - cData[1] - 0.5, to: allTime.length - 0.5, color: '#EEE' },
            // ],
        },

        // legend: {
        //   layout: 'vertical',
        //   align: 'center',
        //   verticalAlign: 'top'
        // },
        chart: {
            type: 'column',
            // zoomType: 'x',
            // panning: true,
            // panKey: 'shift'
        },
        plotOptions: {
            // spline: {
            //     marker: {
            //         radius: 4,
            //         lineColor: '#666666',
            //         lineWidth: 1
            //     }
            // },
            series: {
                borderRadius: {
                    radius: 3
                },
                borderWidth: 0.25,
                // pointWidth: 15
            },
        },
        series: [{
            name: var1,
            data: allData3,

            borderColor: '#000',
            // color: '#ffa500'
        }],

        responsive: {
            rules: [{
                condition: {
                    // maxWidth: 350
                    // width: '600px'
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }

    }

    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeRowsPerPage1 = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData.length) : 0;


    const getTime1 = async (dt) => {
        setIsLoadingHr(true);

        const response = await fetch(
            urlPath + "/rest/getHours?dt=" + dt
        );
        const result = await response.json();

        let r = result.data.map((data) => ({
            product: data.product,
            dt: dayjs(data.dt).toISOString().replace("T", " ").substring(0, 16),
        }));

        const labels = [...new Set(r.map((item) => item.dt))];

        setHours(labels);
        setValue45(labels[0])
        setIsLoadingHr(false);
    };

    const getData = async (dt, wid, lv, var0) => {
        setisLoading1(true);

        console.log(lv)

        let response = null;

        if (var0 === 'PM25') {
            response = await fetch(
                // urlPath + "/rest/getPollution?dt=" + (dt ? dt : '') + "&lv=" + lv + "&type=" + "AOD443" + "&id=" + (wid ? wid : '')
                "https://pm25.gistda.or.th/rest/getPm25by" + (lv == 1 && wid === 'THA' ? "Province" : (lv == 2 ? "Amphoe?pv_idn=" + wid : "Province"))
                // ?dt=" + dt + "&lv=" + lv + "&type=" + var0 + "&id=" + wid
            );
        } else {
            response = await fetch(
                urlPath + "/rest/getPollution?dt=" + (dt ? dt : '') + "&lv=" + lv + "&type=" + var0 + "&id=" + (wid ? wid : '')
            );
        }
        const result = await response.json();

        let r = result.data.map((data) => ({
            value: (data.avg_value || data.pm25) / (['NO2Total', 'NO2Strat', 'NO2Trop', 'SO2'].includes(var0) ? 1e16 : 1),
            th: data.country_th || data.province_th || data.district_th || data.pv_tn || data.ap_tn,
        }));

        // r.value = r.value / (['NO2Total', 'NO2Strat', 'NO2Trop', 'SO2'].includes(var0) ? 10^16 : 1);

        setFetchedData(r);
        // setAvgData(r.reduce((acc, current) => acc + current.value, 0) / (r.length - r.filter(x => x.value === null).length));
        setAvgData(r.reduce((acc, current) => acc + (current.value != null ? current.value : 0), 0) / r.length);
        // setShowTime(result.datetimeThai);

        setisLoading1(false);

        const labels = [...new Set(r.map((item) => item.th))];
        let datasets = [];

        for (var i = 0; i < r.length; i++) {
            // { y: parseInt(Data[i]), color: '#FF0000' }
            let thisValue = parseFloat(r[i].value);
            let thisColor = '#FFF';

            let colorset = 1;
            if (['AOD354', 'AOD443', 'AOD500'].includes(var0)) { colorset = 1 }
            if (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var0)) { colorset = 5 }
            if (['O3Total'].includes(var0)) { colorset = 6 }
            if (['SO2'].includes(var0)) { colorset = 7 }
            if (['SSA354', 'SSA443', 'SSA500'].includes(var0)) { colorset = 2 }
            if (['UVAI'].includes(var0)) { colorset = 3 }
            if (['VIAI'].includes(var0)) { colorset = 4 }
            if (['PM25'].includes(var0)) { colorset = 8 }

            if (colorset != 2) {
                if (thisValue <= [0, null, -1.0, -1.5, 0, 200, 0, 0][colorset - 1]) {
                    thisColor = "#FFCBFF";
                } else if (thisValue <= [0.167, null, -0.167, -1.0, 0.25, 220, 0.25, 15][colorset - 1]) {
                    thisColor = "#EDB7FF";
                } else if (thisValue <= [0.333, null, 0.667, -0.5, 0.50, 240, 0.50, 25][colorset - 1]) {
                    thisColor = "#DA89F5";
                } else if (thisValue <= [0.5, null, 1.5, 0.0, 0.75, 260, 0.75, 37.5][colorset - 1]) {
                    thisColor = "#B95DD8";
                } else if (thisValue <= [0.667, null, 2.33, 0.5, 1.00, 280, 1.00, null][colorset - 1]) {
                    thisColor = "#9B4FB5";
                } else if (thisValue <= [0.833, null, 3.167, 1.0, 1.25, 300, 1.25, null][colorset - 1]) {
                    thisColor = "#86469C";
                } else if (thisValue <= [1.0, null, 4, 1.5, 1.50, 320, 1.50, 75][colorset - 1]) {
                    thisColor = "#723288";
                } else {
                    thisColor = "#723288";
                }
            }
            if (colorset == 2) {
                if (thisValue <= 0.8) {
                    thisColor = "#723288";
                } else if (thisValue <= 0.833) {
                    thisColor = "#86469C";
                } else if (thisValue <= 0.867) {
                    thisColor = "#9B4FB5";
                } else if (thisValue <= 0.9) {
                    thisColor = "#B95DD8";
                } else if (thisValue <= 0.933) {
                    thisColor = "#DA89F5";
                } else if (thisValue <= 0.967) {
                    thisColor = "#EDB7FF";
                } else if (thisValue <= 1.0) {
                    thisColor = "#FFCBFF";
                } else {
                    thisColor = "#FFCBFF";
                }
            }
            datasets.push({ y: thisValue, color: thisColor });
        }
        console.log(datasets)
        setAllData(datasets);
        setAllTime(labels);
        setVar1(var0);
    };

    const getData2 = async (dt, wid, lv, var0) => {
        setisLoading2(true);

        let response = null;

        if (var0 === 'PM25') {
            response = await fetch(
                "https://pm25.gistda.or.th/rest/getPM25by" + (lv === 1 ? "Province" : (lv === 2 ? "Amphoe" : "Province")) + "24hrs?" + (lv === 1 ? "pv_idn" : (lv === 2 ? "ap_idn" : "pv_idn")) + "=" + wid
            );
        } else {
            response = await fetch(
                urlPath + "/rest/getPollution24?dt=" + (dt ? dt : '') + "&lv=" + lv + "&type=" + var0 + "&id=" + (wid ? wid : '')
            );
        }
        const result = await response.json();

        let r = null;
        if (var0 === 'PM25') {
            r = result.graphHistory24hrs.map((data) => ({
                value: data[0],
                // th: data.country_th || data.province_th || data.district_th,
                dt: data[1].slice(0, 16).replaceAll("-", "/").replaceAll("T", " ") + ' ( UTC+7)',
            }));
        } else {
            r = result.data.map((data) => ({
                value: (data.avg_value) / (['NO2Total', 'NO2Strat', 'NO2Trop', 'SO2'].includes(var0) ? 1e16 : 1),
                th: data.country_th || data.province_th || data.district_th,
                dt: new dayjs(data.dt).format('YYYY/MM/DD HH:mm') + ' (UTC)',
            }));
        }


        // setFetchedData(r);
        // setAvgData(r.reduce((acc, current) => acc + current.value, 0) / r.length);
        // setShowTime(result.datetimeThai);

        setisLoading2(false);

        const labels = [...new Set(r.map((item) => item.dt))];
        let datasets = [];

        for (var i = 0; i < r.length; i++) {
            // { y: parseInt(Data[i]), color: '#FF0000' }
            let thisValue = parseFloat(r[i].value);
            let thisColor = '#FFF';

            let colorset = 1;
            if (['AOD354', 'AOD443', 'AOD500'].includes(var0)) { colorset = 1 }
            if (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var0)) { colorset = 5 }
            if (['O3Total'].includes(var0)) { colorset = 6 }
            if (['SO2'].includes(var0)) { colorset = 7 }
            if (['SSA354', 'SSA443', 'SSA500'].includes(var0)) { colorset = 2 }
            if (['UVAI'].includes(var0)) { colorset = 3 }
            if (['VIAI'].includes(var0)) { colorset = 4 }
            if (['PM25'].includes(var0)) { colorset = 8 }

            if (colorset != 2) {
                if (thisValue <= [0, null, -1.0, -1.5, 0, 200, 0, 0][colorset - 1]) {
                    thisColor = "#FFCBFF";
                } else if (thisValue <= [0.167, null, -0.167, -1.0, 0.25, 220, 0.25, 15][colorset - 1]) {
                    thisColor = "#EDB7FF";
                } else if (thisValue <= [0.333, null, 0.667, -0.5, 0.50, 240, 0.50, 25][colorset - 1]) {
                    thisColor = "#DA89F5";
                } else if (thisValue <= [0.5, null, 1.5, 0.0, 0.75, 260, 0.75, 37.5][colorset - 1]) {
                    thisColor = "#B95DD8";
                } else if (thisValue <= [0.667, null, 2.33, 0.5, 1.00, 280, 1.00, null][colorset - 1]) {
                    thisColor = "#9B4FB5";
                } else if (thisValue <= [0.833, null, 3.167, 1.0, 1.25, 300, 1.25, 75][colorset - 1]) {
                    thisColor = "#86469C";
                } else if (thisValue <= [1.0, null, 4, 1.5, 1.50, 320, 1.50, 75][colorset - 1]) {
                    thisColor = "#723288";
                } else {
                    thisColor = "#723288";
                }
            }
            if (colorset == 2) {
                if (thisValue <= 0.8) {
                    thisColor = "#723288";
                } else if (thisValue <= 0.833) {
                    thisColor = "#86469C";
                } else if (thisValue <= 0.867) {
                    thisColor = "#9B4FB5";
                } else if (thisValue <= 0.9) {
                    thisColor = "#B95DD8";
                } else if (thisValue <= 0.933) {
                    thisColor = "#DA89F5";
                } else if (thisValue <= 0.967) {
                    thisColor = "#EDB7FF";
                } else if (thisValue <= 1.0) {
                    thisColor = "#FFCBFF";
                } else {
                    thisColor = "#FFCBFF";
                }
            }
            datasets.push({ y: thisValue, color: thisColor });
        }

        setAllData2(datasets);
        setAllTime2(labels);
    };

    const getData3 = async (dt, wid, lv, var0) => {
        setisLoading3(true);

        let response = null;
        let r = null;

        if (var0 === 'PM25') {
            response = await fetch(
                "https://pm25.gistda.or.th/rest/getPM25by1m" + (lv === 1 ? "Province" : (lv === 2 ? "Amphoe" : "Province")) + "?dt1=" + dt + "&id=" + wid
            );
        } else {
            response = await fetch(
                urlPath + "/rest/getPollution1m?dt=" + (dt ? dt : '') + "&lv=" + lv + "&type=" + var0 + "&id=" + (wid ? wid : '')
            );
        }

        const result = await response.json();

        if (var0 === 'PM25') {
            r = result.graphHistory1m.map((data) => ({
                value: data[0],
                // th: data.country_th || data.province_th || data.district_th,
                date: data[1],
            }));
        } else {
            r = result.data.map((data) => ({
                value: (data.avg_value) / (['NO2Total', 'NO2Strat', 'NO2Trop', 'SO2'].includes(var0) ? 1e16 : 1),
                th: data.country_th || data.province_th || data.district_th,
                date: data.dt || data.วันที่,
            }));
        }

        // console.log()
        // let r = result.map((data) => ({
        //     value: data.data.avg_value || data.PM25,
        //     th: data.data.country_th || data.data.province_th || data.data.district_th || data.จังหวัด || data.อำเภอ,
        //     date: data.data.dt || data.วันที่,
        // }));
        // setFetchedData(r);
        // setAvgData(r.reduce((acc, current) => acc + current.value, 0) / r.length);
        // setShowTime(result.datetimeThai);

        const labels = [...new Set(r.map((item) => item.dt))];
        const datasets = r.map((item) => parseFloat(item.value));

        // console.log(datasets)

        const calendardata = generateChartData(r);

        setAllData3(calendardata);
        setAllTime3(labels);
        setisLoading3(false);
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            borderRadius: (props) => {
                return "4px";
            },
            paddingTop: (props) => {
                return "4px";
            },
            paddingBottom: (props) => {
                return "4px";
            },
            paddingLeft: (props) => {
                return "4px";
            },
            paddingRight: (props) => {
                return "4px";
            },
            width: (props) => {
                return "60px";
            },
            textAlign: (props) => {
                return "center";
            },
            color: (props) => {
                let colorset = 1;
                if (['AOD354', 'AOD443', 'AOD500'].includes(var1)) { colorset = 1 }
                if (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) { colorset = 5 }
                if (['O3Total'].includes(var1)) { colorset = 6 }
                if (['SO2'].includes(var1)) { colorset = 7 }
                if (['SSA354', 'SSA443', 'SSA500'].includes(var1)) { colorset = 2 }
                if (['UVAI'].includes(var1)) { colorset = 3 }
                if (['VIAI'].includes(var1)) { colorset = 4 }
                if (['PM25'].includes(var1)) { colorset = 8 }

                if (!props.value) {
                    return "black";
                }

                if (colorset != 2) {
                    if (props.value <= [0.5, null, 1.5, 0.0, 0.75 * 1e16, 260, 0.75 * 1e16, 37.5][colorset - 1]) {
                        return "black";
                    } else {
                        return "white";
                    }
                }
                if (colorset == 2) {
                    if (props.value <= 0.8) {
                        return "white";
                    } else {
                        return "black";
                    }
                }
            },
            background: (props) => {
                // [0.000, '#FFCBFF'],
                //   [0.167, '#EDB7FF'],
                //   [0.333, '#DA89F5'],
                //   [0.500, '#B95DD8'],
                //   [0.667, '#9B4FB5'],
                //   [0.833, '#86469C'],
                //   [1.000, '#723288']

                let colorset = 1;
                if (['AOD354', 'AOD443', 'AOD500'].includes(var1)) { colorset = 1 }
                if (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) { colorset = 5 }
                if (['O3Total'].includes(var1)) { colorset = 6 }
                if (['SO2'].includes(var1)) { colorset = 7 }
                if (['SSA354', 'SSA443', 'SSA500'].includes(var1)) { colorset = 2 }
                if (['UVAI'].includes(var1)) { colorset = 3 }
                if (['VIAI'].includes(var1)) { colorset = 4 }
                if (['PM25'].includes(var1)) { colorset = 8 }

                if (!props.value) {
                    return "#EDEDED";
                }

                if (colorset != 2) {
                    if (props.value <= [0, null, -1.0, -1.5, 0, 200, 0, 0][colorset - 1]) {
                        return "#FFCBFF";
                    } else if (props.value <= [0.167, null, -0.167, -1.0, 0.25, 220, 0.25, 15][colorset - 1]) {
                        return "#EDB7FF";
                    } else if (props.value <= [0.333, null, 0.667, -0.5, 0.50, 240, 0.50, 25][colorset - 1]) {
                        return "#DA89F5";
                    } else if (props.value <= [0.5, null, 1.5, 0.0, 0.75, 260, 0.75, 37.5][colorset - 1]) {
                        return "#B95DD8";
                    } else if (props.value <= [0.667, null, 2.33, 0.5, 1.00, 280, 1.00, null][colorset - 1]) {
                        return "#9B4FB5";
                    } else if (props.value <= [0.833, null, 3.167, 1.0, 1.25, 300, 1.25, null][colorset - 1]) {
                        return "#86469C";
                    } else if (props.value <= [1.0, null, 4, 1.5, 1.50, 320, 1.50, 75][colorset - 1]) {
                        return "#723288";
                    } else if (props.value > [1.0, null, 4, 1.5, 1.50, 320, 1.50, 75][colorset - 1]) {
                        return "#723288";
                    } else {
                        return "#EDEDED";
                    }
                }
                if (colorset == 2) {
                    if (props.value <= 0.8) {
                        return "#723288";
                    } else if (props.value <= 0.833) {
                        return "#86469C";
                    } else if (props.value <= 0.867) {
                        return "#9B4FB5";
                    } else if (props.value <= 0.9) {
                        return "#B95DD8";
                    } else if (props.value <= 0.933) {
                        return "#DA89F5";
                    } else if (props.value <= 0.967) {
                        return "#EDB7FF";
                    } else if (props.value <= 1.0) {
                        return "#FFCBFF";
                    } else if (props.value > 1.0) {
                        return "#FFCBFF";
                    } else {
                        return "#EDEDED";
                    }
                }

            },
        },
        openButton: {
            color: '#fff',
        }
    }));
    const StyledTableCell = (props) => {
        const classes = useStyles(props);
        return (
            <TableCell
                classes={{ root: classes.root }}
            >
                {props.children}
            </TableCell>
        );
    };

    useEffect(() => {

        getMinMax('AOD443');
        getTime('', 'AOD443');
        setVar0('PM25');
        setVar1('PM25');
        getData('', 10, 1, 'PM25');
        getData2('', 10, 1, 'PM25');
        getData3(new dayjs().format('YYYY-MM-DD'), 10, 1, 'PM25');
        // getTime1(new dayjs().toISOString().replace("T", " ").substring(0, 10))
    }, []);


    return (

        <Box>
            <Box height={64} />

            <Box padding={1} paddingBottom={3}>

                <Grid spacing={1} direction={'row'} container>
                    <Grid item>
                        <div></div>
                        <Fab>
                            {/* <Icon> */}
                            {['AOD354', 'AOD443', 'AOD500'].includes(var0) ? <img src={picAOD} height={'100%'} /> : ''}
                            {['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var0) ? <img src={picNO2} height={'100%'} /> : ''}
                            {var0 === 'O3Total' ? <img src={picO3} height={'100%'} /> : ''}
                            {var0 === 'SO2' ? <img src={picSO2} height={'100%'} /> : ''}
                            {['SSA354', 'SSA443', 'SSA500'].includes(var0) ? <img src={picSSA} height={'100%'} /> : ''}
                            {var0 === 'PM25' ? <img src={picPM25} height={'100%'} /> : ''}
                            {var0 === 'UVAI' ? <img src={picUVAI} height={'100%'} /> : ''}
                            {var0 === 'VIAI' ? <img src={picVIAI} height={'100%'} /> : ''}

                            {/* </Icon> */}
                        </Fab>

                    </Grid>

                    <Grid item>
                        <div>เลือกผลิตภัณฑ์</div>

                        <Box sx={{ minWidth: 120, '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}>
                            <FormControl fullWidth sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}>
                                {/* <InputLabel>เลือกผลิตภัณฑ์</InputLabel> */}
                                <Select
                                    value={var0}
                                    // label="Age"
                                    onChange={(event) => { setVar0(event.target.value); getTime(value.format('YYYY-MM-DD'), event.target.value); }}
                                >
                                    <MenuItem value={'AOD354'}>AOD354</MenuItem>
                                    <MenuItem value={'AOD443'}>AOD443</MenuItem>
                                    <MenuItem value={'AOD500'}>AOD500</MenuItem>
                                    <MenuItem value={'NO2Strat'}>NO2Strat</MenuItem>
                                    <MenuItem value={'NO2Total'}>NO2Total</MenuItem>
                                    <MenuItem value={'NO2Trop'}>NO2Trop</MenuItem>
                                    <MenuItem value={'O3Total'}>O3Total</MenuItem>
                                    <MenuItem value={'SO2'}>SO2</MenuItem>
                                    <MenuItem value={'SSA354'}>SSA354</MenuItem>
                                    <MenuItem value={'SSA443'}>SSA443</MenuItem>
                                    <MenuItem value={'SSA500'}>SSA500</MenuItem>
                                    <MenuItem value={'UVAI'}>UVAI</MenuItem>
                                    <MenuItem value={'VIAI'}>VIAI</MenuItem>
                                    <MenuItem value={'PM25'}>PM2.5 (เช็คฝุ่น)</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>


                    <Grid item>
                        <div>ประเทศ</div>

                        <Autocomplete
                            // disabled={var0 === 'PM25'}
                            value={value0}
                            defaultValue={stl[0]}
                            autoSelect={true}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                setValue0(newValue);
                                setSid(newValue ? newValue.id : null);

                                if (newValue) {
                                    getPvl(newValue.id);
                                    setWid(newValue.id);
                                    console.log(newValue.id);
                                }
                                setPvl([]);
                                setApl([]);
                                setPid(null);
                                setAid(null);
                                setValue1([]);
                                setValue2([]);
                            }}
                            sx={{ width: 200, bgcolor: '#FFF' }}
                            open={open0}
                            onOpen={() => {
                                setOpen0(true);
                            }}
                            onClose={() => {
                                setOpen0(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.th === value.th}
                            getOptionLabel={(option) => option.th || "ทุกประเทศ"}
                            options={stl}
                            loading={loading0}
                            noOptionsText={'ไม่มีข้อมูล'}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    // label="เลือกประเทศ"
                                    placeholder="ทุกประเทศ"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading0 ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />

                        {/* <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">ประเทศ</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="ประเทศ"
                      onChange={handleChange}

                    >
                      <MenuItem key='clear' value='clear'>Clear selection</MenuItem>

                      <MenuItem value={10}>ประเทศไทย</MenuItem>
                      <MenuItem value={20}>สาธารณรัฐประชาธิปไตยประชาชนลาว</MenuItem>
                      <MenuItem value={30}>สาธารณรัฐแห่งสหภาพเมียนมา</MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
                    </Grid>

                    <Grid item>
                        <div>จังหวัด</div>
                        <Box sx={{ minWidth: 120 }}>
                            <Autocomplete
                                value={value1}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setValue1(newValue);
                                    setPid(newValue ? newValue.pv_id : null);

                                    if (newValue) {
                                        getApl(newValue.pv_id);
                                        setWid(newValue.pv_id);
                                        console.log(newValue.pv_id);
                                    }
                                    setValue2([]);
                                    setAid(null);
                                }}
                                sx={{ width: 200, bgcolor: '#FFF' }}
                                open={open1}
                                onOpen={() => {
                                    setOpen1(true);
                                }}
                                onClose={() => {
                                    setOpen1(false);
                                }}
                                isOptionEqualToValue={(option, value) => option.pv_tn === value.pv_tn}
                                getOptionLabel={(option) => option.pv_tn || "ทุกจังหวัด"}
                                options={pvl}
                                loading={loadingPv}
                                noOptionsText={'ไม่มีข้อมูล'}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label="เลือกจังหวัด"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loadingPv ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />

                            {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เลือกจังหวัด</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="จังหวัด"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>จังหวัด</MenuItem>
                      <MenuItem value={20}></MenuItem>
                      <MenuItem value={30}></MenuItem>
                    </Select>
                  </FormControl> */}
                        </Box>
                    </Grid>

                    <Grid item>
                        <div>อำเภอ</div>
                        <Autocomplete
                            value={value2}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                setValue2(newValue);
                                if (newValue) {
                                    setAid(newValue ? newValue.ap_id : null);
                                    setWid(newValue.ap_id);
                                    console.log(newValue.ap_id);
                                }
                            }}
                            sx={{ width: 200, bgcolor: '#FFF' }}
                            open={open2}
                            onOpen={() => {
                                setOpen2(true);
                            }}
                            onClose={() => {
                                setOpen2(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.ap_tn === value.ap_tn}
                            getOptionLabel={(option) => option.ap_tn || "ทุกอำเภอ"}
                            options={apl}
                            loading={loadingAp}
                            noOptionsText={'ไม่มีข้อมูล'}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    // label="เลือกอำเภอ"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingAp ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        {/* <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เลือกอำเภอ</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="อำเภอ"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>อำเภอ</MenuItem>
                      <MenuItem value={20}></MenuItem>
                      <MenuItem value={30}></MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
                    </Grid>


                    <Grid item>
                        <div>เลือกวันเวลา (UTC)</div>
                        <Grid Direction={'row'} spacing={1} container>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disabled={var0 === 'PM25'}
                                        sx={{ width: '150px', }}
                                        minDate={dayjs(minMax.min)}
                                        maxDate={dayjs(minMax.max)}
                                        // label="เลือกวัน"
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue)
                                            // getTime(newValue.toISOString().replace("T", " ").substring(0, 10))
                                            getTime(newValue.format('YYYY-MM-DD'), var0)
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item>
                                <Autocomplete
                                    disabled={var0 === 'PM25'}
                                    value={value45}
                                    onChange={(event, newValue) => {
                                        console.log(newValue);
                                        setValue45(newValue);
                                    }}
                                    sx={{ width: 150 }}

                                    isOptionEqualToValue={(option, value) => option === value}
                                    getOptionLabel={(option) => option || "เลือกเวลา"}
                                    options={hours}
                                    noOptionsText={'ไม่มีข้อมูล'}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            // label="เลือกเวลา"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {isLoadingHr ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid item>
                        <div>ค้นหา</div>
                        <Button disabled={!var0 || (!value45 && !var0 === 'PM25') || (var0 === 'PM25' && sid !== 'THA') || (!(aid || pid) && var0 == 'PM25')} variant="contained" sx={{ boxShadow: 4, }} style={{
                            backgroundColor: !var0 || (!value45 && !var0 === 'PM25') || (var0 === 'PM25' && sid !== 'THA') || (!(aid || pid) && var0 == 'PM25') ? 'rgba(1,1,1,0.5)' : "#FFF",
                            borderRadius: 8,
                        }} onClick={() => {
                            getData(value.format('YYYY-MM-DD') + " " + value45, aid || pid || sid, aid ? 3 : pid ? 2 : sid ? 1 : 0, var0);
                            getData2(value.format('YYYY-MM-DD') + " " + value45, aid || pid || sid, aid ? 2 : pid ? 1 : sid ? 0 : 0, var0);
                            getData3(value.format('YYYY-MM-DD') + " " + value45, aid || pid || sid, aid ? 2 : pid ? 1 : sid ? 0 : 0, var0);
                        }}><img src={search}></img></Button>
                    </Grid>
                </Grid>
            </Box>

            <Box >
                <Grid container padding={1}>
                    <Grid xs={12} md={3} item container padding={1}>
                        <Paper sx={{ width: '100%', minHeight: 210, boxShadow: '4' }}>
                            <Grid xs={12}>
                                <TableContainer sx={{
                                    maxHeight: windowSize[1] - 250,
                                    "&::-webkit-scrollbar": {
                                        width: 8,
                                        height: 8
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        backgroundColor: "#BCBCBC"
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#606060",
                                        borderRadius: 3
                                    },
                                    "&::-webkit-scrollbar-thumb:hover": {
                                        background: "#707070"
                                    }
                                }}>

                                    <Table
                                        stickyHeader
                                        size={dense ? 'small' : 'medium'}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ลำดับ</TableCell>
                                                <TableCell>พื้นที่</TableCell>
                                                <TableCell>ค่าเฉลี่ย</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {isLoading1 ?
                                            <TableBody>
                                                <TableRow>
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                </TableRow>
                                            </ TableBody>
                                            :
                                            <TableBody >
                                                {/* <div width='100%'> */}
                                                {fetchedData.length === 0 ? 'ไม่มีข้อมูล' : ''}
                                                {(rowsPerPage > 0
                                                    ? fetchedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : fetchedData
                                                ).map((row, index) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1 + page * rowsPerPage}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.th}
                                                            {/* {row.dt.match(`[0-9]{6}\/[0-9]{2}`)} {row.dt.match(`_[0-9]{4}_`)} UTC */}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <StyledTableCell value={row.value}>
                                                                {row.value ? (Number(row.value) / (['NO2Total', 'NO2Strat', 'NO2Trop', 'SO2'].includes(var1) ? 1 : 1)).toFixed(2) : 'ไม่มีข้อมูล'}
                                                                {/* {isLoading1 ? '' : var1 === 'SO2' || var1 === 'NO2Trop' ? ' x1e16' : ''} */}
                                                            </StyledTableCell>
                                                            {/* {row.value ? row.value.toFixed(2) : 'ไม่มีข้อมูล'} */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {emptyRows > 0 && (
                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                )}
                                                {/* </div> */}

                                            </TableBody>

                                        }


                                    </Table>
                                </TableContainer>
                                {fetchedData.length > 6 && rowsPerPage !== -1 ? <Button sx={{ width: '100%' }} style={{ backgroundColor: "#6E5777", }} variant='contained' onClick={() => { handleChangeRowsPerPage1(-1) }}>ดูเพิ่มเติม</Button> : ''}
                                {fetchedData.length > 6 && rowsPerPage === -1 ? <Button sx={{ width: '100%' }} style={{ backgroundColor: "#6E5777", }} variant='contained' onClick={() => { handleChangeRowsPerPage1(6) }}>ย่อลง</Button> : ''}
                            </Grid>

                        </Paper>
                    </Grid>

                    <Grid xs={12} md={6} item container padding={1} >
                        <Grid xs={12}>
                            <Paper sx={{ boxShadow: '4', minHeight: 100 }} padding={1}>
                                <Box alignContent={'center'} width={250} height={50} sx={{
                                    background: `linear-gradient(54deg, #382D40, #86469C, #BC98C8)`,
                                    borderTopLeftRadius: 25,
                                    borderBottomRightRadius: 25,

                                    position: 'relative',
                                    top: -25,
                                }} >
                                    <Typography align='center' variant='subtitle1' fontWeight={700} color={'#FFF'}>ค่าเฉลี่ยรายชั่วโมงในวันที่ผ่านมา</Typography>
                                </Box>

                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options2}
                                />
                            </Paper>
                        </Grid>

                        <Grid xs={12}>
                            <Box height={36} />
                        </Grid>

                        <Grid xs={12}>
                            <Paper sx={{ boxShadow: '4', minHeight: 100 }} padding={1} borderRadius={5}>
                                <Box alignContent={'center'} width={200} height={50} sx={{
                                    background: `linear-gradient(54deg, #382D40, #86469C, #BC98C8)`,
                                    borderTopLeftRadius: 25,
                                    borderBottomRightRadius: 25,

                                    position: 'relative',
                                    top: -25,
                                }} >
                                    <Typography align='center' fontWeight={700} color={'#FFF'}>10 อันดับที่มีค่าสูงสุด</Typography>
                                </Box>

                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options1}
                                />
                            </Paper>
                        </Grid>

                    </Grid>

                    <Grid xs={12} md={3} item container padding={1}>
                        <Grid xs={12} item>
                            <Paper sx={{ boxShadow: '4' }} padding={1} minWidth={'100%'}>
                                <Box display={'flex'} flexDirection={'column'} alignContent={'center'} padding={1}>
                                    <Typography align={'center'}>ค่าเฉลี่ย {var1}</Typography>
                                    <Box
                                        sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1 }}
                                    >
                                        <Box sx={{ flexGrow: 1 }}>
                                            {['AOD354', 'AOD443', 'AOD500'].includes(var1) ? <img src={picAOD} height={'100%'} /> : ''}
                                            {['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1) ? <img src={picNO2} height={'100%'} /> : ''}
                                            {var1 === 'O3Total' ? <img src={picO3} height={'100%'} /> : ''}
                                            {var1 === 'SO2' ? <img src={picSO2} height={'100%'} /> : ''}
                                            {['SSA354', 'SSA443', 'SSA500'].includes(var1) ? <img src={picSSA} height={'100%'} /> : ''}
                                            {var1 === 'PM25' ? <img src={picPM25} height={'100%'} /> : ''}
                                            {var1 === 'UVAI' ? <img src={picUVAI} height={'100%'} /> : ''}
                                            {var1 === 'VIAI' ? <img src={picVIAI} height={'100%'} /> : ''}

                                        </Box>
                                        <Box>
                                            <Typography align='right' padding={1} variant="h1" fontWeight='fontWeightMedium' sx={{
                                                fontStyle: 'italic',
                                                backgroundcolor: "primary",
                                                backgroundImage: `linear-gradient(54deg, #382D40, #86469C, #BC98C8)`,
                                                backgroundSize: "100%",
                                                backgroundRepeat: "repeat",
                                                backgroundClip: "text",
                                                WebkitBackgroundClip: "text",
                                                WebkitTextFillColor: "transparent",

                                            }}
                                            >
                                                {isLoading1 ? '' : avgData ? (Number(avgData) / (['NO2Total', 'NO2Strat', 'NO2Trop', 'SO2'].includes(var1) ? 1 : 1)).toFixed(2) : ''}
                                            </Typography>
                                            <Typography>
                                                {isLoading1 ? '' : avgData ? '' : 'ไม่มีข้อมูล'}
                                            </Typography>
                                            {/* <Typography>{isLoading1 ? '' : var1 === 'SO2' || var1 === 'NO2Trop' ? 'x1e16' : ''}</Typography> */}
                                        </Box>
                                    </Box>

                                    <Box display={'flex'} flexDirection={'row'} alignContent={'center'}>
                                    </Box>

                                </Box>
                            </Paper>
                        </Grid>

                        <Grid xs={12} item>
                            <Paper sx={{ boxShadow: '4' }} padding={1} minWidth={'100%'}>
                                <Box display={'flex'} flexDirection={'row'} alignContent={'center'}>
                                    <Box sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1 }}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            {allData2.length === 0 ? '' :
                                                allData2[allData2.length - 1].y - allData2[allData2.length - 2].y > 0 ?
                                                    <img src={increase} /> : allData2[allData2.length - 2].y - allData2[allData2.length - 1].y > 0 ?
                                                        <img src={decrease} /> : <img src={stable} />}
                                        </Box>
                                        <Box display={'flex'} flexDirection={'column'} alignContent={'center'}>
                                            <Typography align='right' padding={1} variant="h5" fontWeight='700' sx={{
                                                fontStyle: 'italic',
                                                backgroundcolor: "primary",
                                                backgroundImage: `linear-gradient(54deg, #6E5777, #6E5777)`,
                                                backgroundSize: "100%",
                                                backgroundRepeat: "repeat",
                                                backgroundClip: "text",
                                                WebkitBackgroundClip: "text",
                                                WebkitTextFillColor: "transparent",
                                            }}
                                            >ค่าเฉลี่ย {var1}</Typography>
                                            <Box justifyContent={'right'}>
                                                <Typography fontWeight={700} color={allData2.length === 0 ? 'yellow' : allData2[allData2.length - 1].y - allData2[allData2.length - 2].y > 0 ?
                                                    'red' : allData2[allData2.length - 2].y - allData2[allData2.length - 1].y > 0 ?
                                                        "green" : "yellow"}>
                                                    {allData2.length === 0 ? '' :
                                                        allData2[allData2.length - 1].y - allData2[allData2.length - 2].y > 0 ?
                                                            'เพิ่มขึ้น' : allData2[allData2.length - 2].y - allData2[allData2.length - 1].y > 0 ?
                                                                "ลดลง" : "คงที่"}

                                                </Typography>
                                                <Typography>
                                                    จากเวลา
                                                    {/* {allData2.length > 0 ? Math.abs(allData2[allData2.length - 1].y - allData2[allData2.length - 2].y).toFixed(2) : ''} */}
                                                </Typography>

                                                <Typography>

                                                    {1 ? allTime2[allTime2.length - 2] : ''}
                                                </Typography>

                                                <Typography>
                                                    {isLoading1 ? '' : avgData ? '' : 'ไม่มีข้อมูล'}
                                                </Typography>
                                                {/* <Typography>{isLoading1 ? '' : var1 === 'SO2' || var1 === 'NO2Trop' ? 'x1e16' : ''}</Typography> */}
                                            </Box>
                                        </Box>
                                    </Box>

                                </Box>
                            </Paper>

                        </Grid>

                        <Grid xs={12} item>
                            <Paper sx={{ boxShadow: '4' }} padding={1} minWidth={'100%'}>
                                <Box display={'flex'} flexDirection={'row'} alignContent={'center'}>
                                    {isLoading3 ? <CircularProgress color="inherit" size={'100%'} /> : <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options4}
                                        allowChartUpdate={true}
                                    />}

                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>


                </Grid>
            </Box>

        </Box >
        // <LineChart labels={labels} data={data} chartType={'bar'} zero={true} />
    )
};

export default Dashboard;
