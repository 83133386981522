import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import Kanit from './assets/fonts/Kanit-Black.ttf';

import { createTheme, ThemeProvider  } from '@mui/material/styles';

const theme = createTheme({
  typography: { fontFamily: 'Kanit' },
  components: {
    // MuiCssBaseline: {
    //   styleOverrides: `
    //     @font-face {
    //       font-family: 'Kanit';
    //       font-style: normal;
    //       font-display: swap;
    //       font-weight: 100;
    //       src: local('Kanit-Black'), local('Kanit-Regular'), url(${Kanit}) format('ttf');
    //     }
    //   `,
    // },
  },
});

// unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,

  document.getElementById('root')
);
