import React from "react";
import ReactDOM from "react-dom";
import SwaggerUI from "swagger-ui-react";

import { Box, Grid } from "@mui/material";

import "swagger-ui-react/swagger-ui.css";

const Doc = require('./openapi.yaml');

const Api = () => {
    return (
        <div>
            <Grid xs={12}>
                <Box height={76} />
            </Grid>
            <Grid xs={12}>
                <SwaggerUI url={Doc} />
            </Grid>
        </div>
    );
}

export default Api;
