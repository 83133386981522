import React, { useRef, useState, useEffect } from 'react';

import Axios from 'axios';

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'

import {
    Box,
    InputLabel,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    Paper,
    Select,
    Skeleton,
    Modal
} from "@mui/material";

// import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableFooter from '@mui/material/TableFooter';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';

import tificon from '../img/tif.svg'
import csvicon from '../img/csv.svg'

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import search from '../img/search1.svg';



const FileDownload = require('js-file-download');

const urlPath = 'https://pollution-app-33s3yn5pmq-as.a.run.app';

function createData(id, name, calories, fat, carbs, protein) {
    return {
        id,
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}

let rows = [];
// const var0 = ['AOD500'];
const p_color = '#2E2335';


// const [rows, setRows] = React.useState([]);
// const [var0, setVar0] = useState(['AOD500']);

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'ลำดับ',
    },
    {
        id: 'calories',
        numeric: true,
        disablePadding: false,
        label: 'วันที่',
    },
    {
        id: 'fat',
        numeric: true,
        disablePadding: false,
        label: 'ชื่อภาพ',
    },
    {
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'รายละเอียด',
    },
    {
        id: 'protein',
        numeric: true,
        disablePadding: false,
        label: 'ดาวเทียม',
    },
    {
        id: 'a',
        numeric: true,
        disablePadding: false,
        label: 'ไฟล์ดาวน์โหลด',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    ดาวน์โหลด
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


const Download = () => {


    const [fetchedData, setFetchedData] = useState([]);
    const [isLoading1, setisLoading1] = useState(false);
    const [isLoading2, setisLoading2] = useState(false);

    const [minMax, setMinMax] = useState([]);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [t0, setT0] = React.useState(null);
    const [t1, setT1] = React.useState(null);
    const [var0, setVar0] = React.useState(null);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = fetchedData.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
        console.log(fetchedData)
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(fetchedData, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const [visibleRows1, setVisibleRows1] = React.useState(
        () =>
            stableSort(fetchedData, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    const [admin, setAdmin] = React.useState('');

    // const handleSelect = (event) => {
    //     setisLoading2(true);
    //     setAdmin(event.target.value);
    //     setisLoading2(false);
    // };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.screen.width > 700 ? 700 : 350,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const onKeyDown = (e) => {
        e.preventDefault();
    };


    const getData = async (date1, date2, var0) => {
        setisLoading1(true);
        let t0 = date1;
        let t1 = date2;
        try {
            t0 = date1.format('YYYY-MM-DD');
            t1 = date2.format('YYYY-MM-DD');
        } catch {

        }
        // date = date.add(1, 'day')
        // console.log('fetch ' + JSON.stringify(date1).split('T')[0].slice(1))
        // console.log(`${urlPath}/rest/gettiflist?t0=${JSON.stringify(date1).split('T')[0].slice(1)}&t1=${JSON.stringify(date2).split('T')[0].slice(1)}&var=${var0}`)
        const response = await fetch(
            urlPath + "/rest/gettiflist?t0=" + t0 + "&t1=" + t1 + "&var=" + var0
        );
        const result = await response.json();

        let r = result.map((data) => ({
            dt: data
        }));
        setFetchedData(r);
        console.log(r);

        setisLoading1(false);
        setisLoading2(false);

        // setVisibleRows1(fetchedData);

        // rows = result.map((data) => ({
        //     dt: data
        // }))
        // console.log(fetchedData);
    };

    const getMinMax = async () => {

        const response = await fetch(
            urlPath + "/rest/getMinMaxDate"
        );
        const result = await response.json();

        setT0(dayjs(result.data.max));
        setT1(dayjs(result.data.max));

        setMinMax(result.data);

        return dayjs(result.data.max);
    };

    const handleLoad1 = async (name) => {
        // console.log(date)
        Axios({
            url: urlPath + '/rest/gettif?var0=' + name,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, name);
        });

    };

    const handleLoad2 = async (var0, dt) => {
        // console.log(date)
        Axios({
            url: urlPath + '/rest/getPollitionAsCSV?type=' + var0 + '&dt=' + dt,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, `${var0}_${dt}.csv`);
        });

    };

    const handleLoad3 = async (var0, dt) => {
        // console.log(date)
        Axios({
            url: urlPath + '/rest/getPollitionAsCSV?type=' + var0 + '&dt=' + dt,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, `${var0}_${dt}.csv`);
        });

    };

    // const thaiAdmin = () => {
    //     if (admin === 'Province') {
    //         return 'จังหวัด';
    //     } else if (admin === 'Amphoe') {
    //         return 'อำเภอ';
    //     } else if (admin === 'Tambon') {
    //         return 'ตำบล';
    //     } else {
    //         return '';
    //     }
    // }

    useEffect(() => {
        async function fetchMyAPI() {
            let r = await getMinMax();

            await getData(r, r, 'AOD443');
        }

        setVar0('AOD443');

        setisLoading1(false);
        setisLoading2(true);

        fetchMyAPI()

        // const today = new dayjs();
        // const dlevel = 'Province';
        // setAdmin(dlevel);

        // setT0(today);
        // setT1(today);

        // setisLoading1(false);
        // setisLoading2(false);

        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    return (
        <div className="App" style={{ marginTop: 60 }}>
            {/* <h2>Height: {windowSize[1]}</h2> */}

            <Grid container spacing={{ xs: 1, md: 2 }} >

                <Grid item xs={12}>
                    <Item>

                        {/* <Grid item xs={12} sx={{ paddingTop: "14px" }} >
                            <a href="https://www.mhesi.go.th/"><img src={mhesiPic} height={45}></img></a>
                            <a href="https://www.gistda.or.th/"><img src={gistdaPic} height={45}></img></a>
                            <a href="https://www.nrct.go.th/"><img src={nrctPic} height={45}></img></a>
                            <a href="https://www.pcd.go.th/"><img src={pcdPic} height={45}></img></a>
                            <a href="https://www.cmuccdc.org/"><img src={ccdcPic} height={45}></img></a>
                            <a href="https://www.ku.ac.th/"><img src={kuPic} height={45}></img></a>
                        </Grid> */}


                        <Grid item xs={12} >
                            <Box height={30} />
                            {/* <Item><h1>ดาวน์โหลดข้อมูลจากระบบฐานข้อมูล GEMS </h1></Item> */}
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Grid item xs={12} container direction={'row'} spacing={1}>
                        <Grid item sx={{ paddingBottom: "15px" }}>
                            <Box sx={{ maxWidth: 250 }} align='center'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="เลือกวันที่เริ่มต้น"
                                        minDate={dayjs(minMax.min)}
                                        maxDate={dayjs(minMax.max)}
                                        value={t0}
                                        onChange={(newValue) => {
                                            setT0(newValue);
                                            // getData(newValue, t1, var0);
                                            // console.log(t0);
                                        }}
                                        format="DD/MM/YYYY"
                                        // renderInput={(params) => <TextField {...params} />}
                                        InputProps={{ readOnly: true }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item sx={{ paddingBottom: "15px" }}>
                            <Box sx={{ maxWidth: 250 }} align='center'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="เลือกวันที่สิ้นสุด"
                                        minDate={dayjs(minMax.min) && t0}
                                        maxDate={dayjs(minMax.max)}
                                        value={t1}
                                        onChange={(newValue) => {
                                            setT1(newValue);
                                            // getData(t0, newValue, var0);
                                            // console.log(newValue);
                                        }}
                                        format="DD/MM/YYYY"
                                        // renderInput={(params) => <TextField {...params} />}
                                        InputProps={{ readOnly: true }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box width={120}>
                                <FormControl fullWidth>
                                    {/* <InputLabel>เลือกผลิตภัณฑ์</InputLabel> */}
                                    <Select
                                        value={var0}
                                        label="ผลิตภัณฑ์"
                                        onChange={(event) => { setVar0(event.target.value) }}
                                    >
                                        <MenuItem value={'AOD354'}>AOD354</MenuItem>
                                        <MenuItem value={'AOD443'}>AOD443</MenuItem>
                                        <MenuItem value={'AOD500'}>AOD500</MenuItem>
                                        <MenuItem value={'NO2Strat'}>NO2Strat</MenuItem>
                                        <MenuItem value={'NO2Total'}>NO2Total</MenuItem>
                                        <MenuItem value={'NO2Trop'}>NO2Trop</MenuItem>
                                        <MenuItem value={'O3Total'}>O3Total</MenuItem>
                                        <MenuItem value={'SO2'}>SO2</MenuItem>
                                        <MenuItem value={'SSA354'}>SSA354</MenuItem>
                                        <MenuItem value={'SSA443'}>SSA443</MenuItem>
                                        <MenuItem value={'SSA500'}>SSA500</MenuItem>
                                        <MenuItem value={'UVAI'}>UVAI</MenuItem>
                                        <MenuItem value={'VIAI'}>VIAI</MenuItem>
                                        <MenuItem value={'PM25'}>PM2.5</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                        </Grid>
                        <Grid item sx={{ paddingBottom: "15px" }}>
                            <Button variant="contained" sx={{ boxShadow: 4, }} style={{
                                backgroundColor: "#FFF",
                                borderRadius: 8,
                            }} disabled={!var0 && t0 && t1} onClick={() => getData(t0, t1, var0)}>
                                <img src={search}></img>
                            </Button>
                        </Grid>

                        {/* <Grid item xs={12} sx={{ paddingBottom: "15px" }}>
                            <Box sx={{ maxWidth: 250 }} align='center'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">เลือกระดับเขตการปกครอง</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={admin}
                                        label="เลือกระดับเขตการปกครอง"
                                        onChange={handleSelect}
                                    >
                                        <MenuItem value={"Province"}>ระดับจังหวัด</MenuItem>
                                        <MenuItem value={"Amphoe"}>ระดับอำเภอ</MenuItem>
                                        <MenuItem value={"Tambon"}>ระดับตำบล</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid> */}
                    </Grid>


                    <Grid item xs={12} md={12} align='center'>



                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                                <TableContainer sx={{
                                    maxHeight: windowSize[1] - 250,
                                    "&::-webkit-scrollbar": {
                                        width: 8,
                                        height: 8
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        backgroundColor: "#BCBCBC"
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#606060",
                                        borderRadius: 3
                                    },
                                    "&::-webkit-scrollbar-thumb:hover": {
                                        background: "#707070"
                                    }
                                }}>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ลำดับ</TableCell>
                                                <TableCell>วันที่</TableCell>
                                                <TableCell>ผลิตภัณฑ์</TableCell>
                                                <TableCell>ชื่อภาพ</TableCell>
                                                {/* <TableCell>รายละเอียด</TableCell> */}
                                                <TableCell align='right'>ดาวเทียม</TableCell>
                                                <TableCell>ดาวน์โหลดไฟล์</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {isLoading1 ?
                                            <TableBody>
                                                <TableRow>
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                </TableRow>
                                            </ TableBody>
                                            :

                                            fetchedData.length === 0 ?
                                                <TableBody>
                                                    <TableRow>
                                                        {var0 && !isLoading2 ?
                                                            <TableCell colSpan={6}>
                                                            {var0 === 'PM25' ? <a href="https://pm25.gistda.or.th/download">สามารถดาวน์โหลดได้ที่ pm25.gistda.or.th</a> : `ไม่พบข้อมูล ${var0} ในช่วงเวลาดังกล่าวในฐานข้อมูล`}
                                                            </TableCell>
                                                            :
                                                            <TableCell colSpan={6}>โปรดเลือกผลิตภัณฑ์</TableCell>
                                                        }
                                                        
                                                    </TableRow>
                                                </ TableBody> 
                                                :
                                                <TableBody>
                                                    {(rowsPerPage > 0
                                                        ? fetchedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : fetchedData
                                                    ).map((row, index) => (
                                                        <TableRow key={row.name}>
                                                            <TableCell component="th" scope="row">
                                                                {index + 1 + page * rowsPerPage}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {row.dt.replace(/(.*)([0-9]{4})([0-9]{2})\/([0-9]{2})(.*)_([0-9]{2})([0-9]{2})_(.*)/, '$2/$3/$4 $6:$7 (UTC)')}
                                                                {/* {row.dt.match(`[0-9]{6}\/[0-9]{2}`)} {row.dt.match(`_[0-9]{4}_`)} UTC */}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {row.dt.replace(/(.*)\/(.*)\/(.*)\/(.*)/, '$1')}
                                                            </TableCell>
                                                            <TableCell style={{ width: 160 }} align="right">
                                                                {row.dt.split('/').slice(-1)}
                                                            </TableCell>

                                                            <TableCell style={{ width: 160 }} align="right">
                                                                GK2
                                                            </TableCell>
                                                            <TableCell style={{ width: 160 }} align="right">
                                                                <Box direction={'row'}>
                                                                    <Button><img src={tificon} width={36} onClick={() => handleLoad1(row.dt)} /></Button>
                                                                    <Button><img src={csvicon} width={36} onClick={() => handleLoad2(var0, row.dt.replace(/(.*)([0-9]{4})([0-9]{2})\/([0-9]{2})(.*)_([0-9]{2})([0-9]{2})_(.*)/, '$2-$3-$4 $6:$7 (UTC)'))} /></Button>
                                                                    {/* {var1==='PM25'?<Button><img src={tificon} width={36} onClick={() => handleLoad1(row.dt)} /></Button>:''} */}
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 53 * emptyRows }}>
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>



                                        }


                                    </Table>
                                </TableContainer>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 8, { label: 'All', value: -1 }]}
                                            colSpan={3}
                                            count={fetchedData.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            slotProps={{
                                                select: {
                                                    inputProps: {
                                                        'aria-label': 'rows per page',
                                                    },
                                                    native: true,
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Paper>
                            {/* <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            /> */}
                        </Box>

                    </Grid>
                </Grid>

            </Grid>

        </div >
    );
}

export default Download;
