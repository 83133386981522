import * as React from 'react';
import { useRef, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Link, NavLink, useLocation } from 'react-router-dom'

import { createTheme } from '@mui/material/styles';
import { Modal } from '@mui/material';

import Info from '../component/TableInfo.js'
import Gistda from '../../img/gistda.svg'
import InfoIcon from '@mui/icons-material/Info';



const theme = createTheme({
    palette: {
        ochre: {
            main: '#E3D026',
            light: '#E9DB5D',
            dark: '#A29415',
            contrastText: '#242105',
        },
    },
});


const p_color = '#2E2335';

const drawerWidth = 240;
// const navItems = ['Home', 'About', 'Contact'];
const navItems = [
    { text: 'สถานการณ์มลพิษทางอากาศ', href: '/' },
    { text: 'สรุปภาพรวม', href: '/dashboard' },
    { text: 'ดาวน์โหลดข้อมูล', href: '/download' },
    { text: 'บริการข้อมูล', href: '/api' },
    { text: 'ติดต่อเรา', href: '/contact' },
    // { text: 'เมฆ', href: '/' },
    // { text: 'สถานการณ์ภัยพิบัติ', href: '/' },
];

function DrawerAppBar(props) {
    const currentPage = useLocation();

    console.log(currentPage.pathname)

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: window.screen.width > 700 ? 700 : 350,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '25px',
    };

    const [openInfo, setOpenInfo] = useState(false);
    const handleCloseInfo = () => setOpenInfo(false);
    const handleOpenInfo = () => setOpenInfo(true);

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ bgcolor: p_color, textAlign: 'center' }}>
            {/* <Typography variant="h6" sx={{ my: 2 }}>
                GEMS
            </Typography> */}
            <img src={Gistda}></img>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.text} disablePadding>

                        <Link
                            key={1}
                            to={item.href}
                            style={{ textDecoration: 'none' }}>
                            <ListItemButton sx={{ textAlign: 'center', width: '100%' }}>
                                <ListItemText primary={<Typography variant="body2" style={{ color: currentPage.pathname === item.href ? '#FDD36A' : '#FFFFFF' }}>{item.text}</Typography>} />
                            </ListItemButton>
                        </Link>

                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }} color={'#FFF'}>
            <Modal open={openInfo} onClose={handleCloseInfo} sx={{ zIndex: 'tooltip' }}>
                <Box sx={style}>
                    <Info />

                </Box>
            </Modal>

            <CssBaseline />
            <AppBar component="nav" sx={{ bgcolor: p_color, height: '64px' }} >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        <img src={Gistda}></img>
                    </Box>

                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <IconButton onClick={handleOpenInfo}>
                            <InfoIcon style={{ color: 'white' }} />
                        </IconButton>
                        {navItems.map((item) => (
                            <NavLink to={item.href} style={{ textDecoration: 'none' }}>
                                <Button key={item.text} style={{ color: currentPage.pathname === item.href ? '#FDD36A' : '#FFFFFF' }}>
                                    {/* <Typography variant="body2" style={{ color: currentPage === item.text ? '#FF00FF' : '#00FFFF' }}>{item.text}</Typography> */}
                                    {item.text}
                                </Button>
                            </NavLink>
                            // <Link to={item.href} style={{ textDecoration: 'none' }}>
                            //     <Button key={item.text} >
                            //         <Typography variant="body2" style={{ color: currentPage === item.text ? '#FF00FF' : '#00FFFF' }}>{item.text}</Typography>
                            //     </Button>
                            // </Link>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>

        </Box>
    );
}

DrawerAppBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default DrawerAppBar;